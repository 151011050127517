export interface IChain {
  name: string;
  decimal: number;
  hex: string;
  rpcURL?: string;
  explorer?: string;
}

export class Wallet {
  accounts: string[];
  chain?: IChain;

  constructor() {
    this.accounts = [];
  }
}
