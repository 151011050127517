import WalletConnectProvider from "@walletconnect/web3-provider";
import WalletLink from "walletlink";
import Web3Modal from "web3modal";
import styled from "@emotion/styled";
import { OPEN_SEA_URL } from "shared/constants";

interface ConnectProps {
  handleProvider: (deep: boolean, provider: any) => void;
}

const modal = new Web3Modal({
  network: "mainnet",
  cacheProvider: false,
  disableInjectedProvider: false,
  providerOptions: {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        infuraId: "e08f25d6cba1481a8ea2cd2eb30fd267",
      },
    },
    "custom-walletlink": {
      package: WalletLink,
      display: {
        name: "Coinbase",
        description: "Connect to Coinbase Wallet",
        logo: "/assets/images/coinbase.jpg",
      },
      options: {
        appName: "Coinbase",
        chainId: 1,
        networkUrl:
          "https://mainnet.infura.io/v3/e08f25d6cba1481a8ea2cd2eb30fd267",
      },
      connector: async (_, { appName, networkUrl, chainId }) => {
        const walletLink = new WalletLink({ appName });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    },
  },
});

export default function Connect({ handleProvider }: ConnectProps) {
  const handleConnect = async (deep: boolean) => {
    try {
      const provider = await modal.connect();
      handleProvider(deep, provider);
    } catch (e) {
      if (e && e !== "Modal closed by user")
        alert("Connection failed, please try again");

      return false;
    }
  };

  return (
    <Container>
      <div className="connect-container">
        {/* <div className="connect-card">
          <span>
            PUBLIC MINTING OPEN
            <br />
            .08 ETH
          </span>
          <button className="btn btn-glow" onClick={() => handleConnect(true)}>
            Connect
          </button>
        </div> */}
        <div className="connect-card">
          <span>SEE COLLECTION ON OPENSEA</span>
          <a className="btn btn-glow" href={OPEN_SEA_URL} target="_blank">
            OPENSEA
          </a>
        </div>
      </div>
    </Container>
  );
}

// const Button = styled.button`
//   background: #fff;
//   border: 1px solid #fff;
//   color: #000;
//   cursor: pointer;
//   font-weight: 800;
//   margin: 0;
//   padding: 1em 2em;
//   font-size: 20px;
//   max-width: 480px;
//   min-height: 100px;
//   margin: 0 auto;
//   text-align: center;
//   text-transform: uppercase;
// `;

const Container = styled.div`
  // width: 100vw;
  text-align: center;
  // position: absolute;
  // bottom: 200px;
`;
