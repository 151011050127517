import styled from "@emotion/styled";

import Web3 from "web3";
import EthereumSession from "lib/eth-session";
import MintAPI from "../lib/mint-api";
import { useState } from "react";
// import { ChangeEventHandler } from "react";

interface MintProps {
  accounts: string[];
  mintAPI: MintAPI;
  session: EthereumSession;
}

interface SendArgs {
  from: string;
  value: string;
  type?: string;
}

export default function Mint({ accounts, mintAPI, session }: MintProps) {
  const [quantity, setQuantity] = useState<number>(1);

  const formatAddress = (account: string) => {
    return (
      account.substring(0, 6) + "..." + account.substring(account.length - 4)
    );
  };

  const handleMint = async () => {
    try {
      if (!(session && session.contract && session.wallet)) return;

      const proof: string[] = [];
      const account = session.wallet.accounts[0];
      const isPresaleActive: string = await session.contract.methods
        .isPresaleActive()
        .call();
      const isMainsaleActive: string = await session.contract.methods
        .isMainsaleActive()
        .call();
      if (isMainsaleActive) {
        //no-op
      } else if (isPresaleActive) {
        if (!mintAPI.getProof(account, proof)) {
          alert("The selected wallet does not have presale access");
          return;
        }
      } else {
        alert("Sale has not started");
        return;
      }

      const PRICE: string = await session.contract.methods.PRICE().call();
      const totalBN = Web3.utils
        .toBN(`${quantity}`)
        .mul(Web3.utils.toBN(`${PRICE}`));

      let sendArgs: SendArgs = {
        from: account,
        value: totalBN.toString(),
      };

      try {
        await session.contract.methods
          .mint(quantity, proof)
          .estimateGas(sendArgs);
        await session.contract.methods.mint(quantity, proof).send(sendArgs);
      } catch (err: any) {
        if (err.code && err.code === -32602) {
          sendArgs = {
            from: account,
            value: totalBN.toString(),
            type: "0x1",
          };
          await session.contract.methods
            .mint(quantity, proof)
            .estimateGas(sendArgs);
          await session.contract.methods.mint(quantity, proof).send(sendArgs);
        } else {
          throw err;
        }
      }

      alert("Mint successful!");
    } catch (err) {
      const ethErr: any = EthereumSession.getError(err);
      if (ethErr.code === 4001) {
        alert("Please try again and confirm the transaction");
      } else if (ethErr.message) {
        alert(ethErr.message);
      } else {
        alert(String(err));
      }
    }
  };

  function handleQuantity(event: Event): void {
    const qty = parseInt((event.target as HTMLSelectElement).value);
    if (!isNaN(qty)) {
      setQuantity(qty);
    }
  }

  return (
    <Container>
      {/* <div className="mint-container">
        <div className="mint-card">
          <span>
            PUBLIC MINTING OPEN
            <br />
            .08 ETH
          </span>
          <Header>Join the MEFaverse</Header>
          <div className="dropdown">
            <Select
              value={quantity}
              onChange={(evt: any) => handleQuantity(evt)}
            >
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
              <Option value="6">6</Option>
              <Option value="7">7</Option>
              <Option value="8">8</Option>
              <Option value="9">9</Option>
              <Option value="10">10</Option>
            </Select>
            <button className="btn-mint" onClick={handleMint}>
              Mint
            </button>
          </div>
          <AccountContainer>
            <strong>Connected:</strong> {formatAddress(accounts[0])}
          </AccountContainer>
        </div>
      </div> */}
    </Container>
  );
}

const Container = styled.div`
  // position: absolute;
  // bottom: 100px;
  // max-width: 480px;
  // min-height: 200px;
  // padding: 20px;
  // margin: 0 auto;
  // border: 10px solid #27343d;
  // border-radius: 10px;
  // background: rgba(110, 137, 165, 0.75);
  // text-align: center;
`;

const AccountContainer = styled.div`
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 0.8em;
  margin: 1em 0;
  text-align: center;
`;

const Header = styled.h1`
  color: #fff;
  margin: 0 auto 20px auto;
  display: block;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 600;
  font-size: 1.4rem;
`;

// const Button = styled.button`
//   background: #fff;
//   border: 1px solid #fff;
//   color: #fff;
//   cursor: pointer;
//   font-weight: 800;
//   margin: 1em;
//   padding: 0.3em 1em;
//   border-radius: 10px;
// `;

const Select = styled.select`
  border-radius: 5px;
  font-size: 1.6em;
  padding-left: 0.5em;
  vertical-align: sub;
`;

const Option = styled.option`
  font-size: 0.8em;
  padding-left: 0.5em;
`;
