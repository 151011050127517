export enum Pages {
  HOME = "/",
  HOME_BASE = "/about-us",
  SOLUTION = "/journey",
  LINEUP = "/lineup",
  CAST = "/cast",
  MINT = "/mint",
}

export enum Breakpoints {
  DESKTOP = "1440px",
  TABLET = "1194px",
  TABLET_SMALL = "1000px",
  MOBILE = "470px",
}

export const HOME_BACKGROUND_COLOR = "#ffffff";
export const HOME_BASE_BACKGROUND_COLOR = "#ffffff";
export const THE_SOLUTION_BACKGROUND_COLOR = "#ffffff";
export const THE_LINEUP_BACKGROUND_COLOR = "#fffffff";
export const THE_CAST_BACKGROUND_COLOR = "#ffffff";

export const TWITTER_URL = "https://twitter.com/mefaverse";
export const DISCORD_URL = "https://discord.gg/MEFaverse";
export const OPEN_SEA_URL = "https://opensea.io/collection/mefaverse/";
