import styled from "@emotion/styled";
import { Breakpoints } from "shared/constants";

export const Copy = styled.p`
  font-size: 20px;
  line-height: 1.2;
  font-weight: 300;
  // max-width: 600px;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    font-size: 18px;
  }

  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    font-size: 16px;
  }
`;
