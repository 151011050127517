import styled from "@emotion/styled";

interface CastItemProps {
  image: string;
  nickname: string;
  name: string;
}

export function CastItem({ image, nickname, name }: CastItemProps) {
  return (
    <Link target="_blank" rel="noreferrer">
      <Image src={image} alt="" />
      <Nickname>{nickname}</Nickname>
      <Name>{name}</Name>
    </Link>
  );
}

const Link = styled.a`
  display: flex;
  flex-direction: column;
  padding: 2rem 1.5rem;
  align-items: center;
  @media screen and (max-width: 900px) {
    padding: 2rem 1rem;
  }
`;

const Image = styled.img`
  width: 250px;
  height: 250px;
  justify-self: center;
  border-radius: 100%;
  margin-bottom: 2px;
  border: 5px solid #ffc401;
  margin-bottom: 5px;

  // @media screen and (max-width: 800px) {
  //   width: 250px;
  //   height: 250px;
  // }
  @media screen and (max-width: 685px) {
    width: 200px;
    height: 200px;
  }
`;

const Name = styled.p`
  font-weight: 300;
  font-size: 22px;
  text-align: center;
  margin-bottom: 5px;
  color: black;
  font-family: "Montserrat", sans-serif;
  margin-top: 1rem;
  line-height: 1;
  min-height: 20px;
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

const Nickname = styled.p`
  font-weight: 600;
  font-size: 24px;
  text-align: center;
  margin-bottom: -3px;
  color: black;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin-top: 1rem;
  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 520px) {
    font-size: 18px;
  }
`;
