import { useState } from "react";
import { useWindowSize } from "hooks/useWindowSize";
import MintAPI from "lib/mint-api";

import EthereumSession from "lib/eth-session";
import ABI from "../lib/ABI";
import accessList from "../lib/access-list";

import Connect from "components/Connect";
import Mint from "components/Mint";

import styled from "@emotion/styled";
import { MobileMenu } from "components/MobileMenu";
import { Breakpoints } from "shared/constants";
// import desktopHomeImage from "shared/images/home-page-desktop.png";
// import mobileHomeImage from "shared/images/home-page-mobile.png";
import homepageLG from "shared/images/MefaverseLogo.jpg";

import "../homepage.css";

interface HomeProps {
  isMobileMenuOpen: boolean;
}

const session = new EthereumSession({
  chain: EthereumSession.COMMON_CHAINS[1],
  contractAddress: "0x94bcCFcBeFd6102b953A85AFB81236958c10FEed",
  contractABI: ABI,
});

export function Home({ isMobileMenuOpen }: HomeProps) {
  const [accounts, setAccounts] = useState<string[]>([]);
  const [mintAPI, setMintAPI] = useState<MintAPI>();
  const windowSize = useWindowSize();

  const handleProvider = async (deep: boolean, provider: any) => {
    await session.connectWeb3(deep, provider);

    const mintAPI = new MintAPI();
    mintAPI.loadAccessList(accessList);
    setMintAPI(mintAPI);
    setAccounts(session.wallet.accounts);
  };

  if (windowSize && windowSize.width < 470) {
    return (
      <>
        {isMobileMenuOpen ? (
          <MobileMenu />
        ) : (
          <>
            {mintAPI && accounts.length ? (
              <Mint accounts={accounts} mintAPI={mintAPI} session={session} />
            ) : (
              <Connect handleProvider={handleProvider} />
            )}
          </>
        )}
      </>
    );
  } else {
    return (
      <div className="background-holder">
        <Container>
          <DesktopImage src={homepageLG} />
          {mintAPI && accounts.length ? (
            <Mint accounts={accounts} mintAPI={mintAPI} session={session} />
          ) : (
            <Connect handleProvider={handleProvider} />
          )}
        </Container>
      </div>
    );
  }
}

const Container = styled.div`
  position: relative;
  height: 750px;
  width: 100%;
`;

const DesktopImage = styled.img`
  position: absolute;
  top: 70%;
  left: 0;
  background-color: white;
  @media screen and (max-width: ${Breakpoints.TABLET}) {
    max-height: 510px;
    max-width: 800px;
  }
  @media screen and (max-width: 800px) {
    max-height: 510px;
    max-width: 700px;
  }
  @media screen and (max-width: 683px) {
    display: none;
  }
`;

// const MobileImage = styled.img`
//   max-width: 292px;
//   margin: -50px auto 50px;
//   pointer-events: none;
// `;
