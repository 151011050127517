import styled from "@emotion/styled";
import { CastItem } from "components/CastItem";
import { MobileMenu } from "components/MobileMenu";
import { Spacer } from "components/Spacer";
import { useWindowSize } from "hooks/useWindowSize";
import { Breakpoints, THE_LINEUP_BACKGROUND_COLOR } from "shared/constants";
import meth from "shared/images/1_methodman.jpg";
import cliff from "shared/images/2_cliff_johnson.jpg";
import jazz from "shared/images/3_jasmine_reed.jpg";
import ray from "shared/images/4_raymond_pilar.jpg";
import wukong from "shared/images/5_wukong.jpg";
import winbush from "shared/images/6_winbush.jpg";
import tatts from "shared/images/7_johnny_sogn.jpg";
import profile from "shared/images/8_aanya_singh.jpg";
import mello from "shared/images/9_asen_petrov.jpg";
import engage from "shared/images/10_blake_marco.jpg";
import edge from "shared/images/11_benny_francisco.jpg";
import divine from "shared/images/12_leotie_mellody_goforth.jpg";
import rex from "shared/images/13_jethro_loben.jpg";
import calamity from "shared/images/14_calamity_jane_liu.jpg";
import sleet from "shared/images/15_maxime_dubois.jpg";
import blackout from "shared/images/16_mara_collins.jpg";
import cinder from "shared/images/17_roscoe_powers.jpg";
import nix from "shared/images/18_nicole_isabelle_xander.jpg";
import simon from "shared/images/19_simon_johnson.jpg";
import sage from "shared/images/20_zurie_collins.jpg";
import will from "shared/images/21_will_b_myne.jpg";

interface CastProps {
  isMobileMenuOpen: boolean;
}

export function Cast({ isMobileMenuOpen }: CastProps) {
  if (isMobileMenuOpen) {
    return <MobileMenu />;
  } else {
    return (
      <PageContainer backgroundColor={THE_LINEUP_BACKGROUND_COLOR}>
        <ContentContainer>
          <Spacer size={50} />
          <Header>The Cast.</Header>
          <IntroContainer>
            <Intro>
              We refer to them as the cast because that’s what they are. Each
              one has a back-story, a personality, strengths, weaknesses, loves,
              and hates. A great deal of time went into the design and
              development of each to get them just right. But these PFPs aren’t
              just beautiful works of art. They are your key into the MEFaverse.
              Without one, entry into the world is prohibited until further
              notice.
            </Intro>
          </IntroContainer>
          <Flex>
            <CastItem image={meth} nickname="'METHOD MAN'" name="" />
            <CastItem
              image={cliff}
              nickname="'Cliff'"
              name="Cliff D. Johnson"
            />
            <CastItem
              image={jazz}
              nickname="'Jaz'"
              name="Jasmine Reed
              "
            />
            <CastItem image={ray} nickname="'The Brain'" name="Raymond Pilar" />
            <CastItem image={wukong} nickname="'WuKong'" name="" />
            <CastItem image={winbush} nickname="'Winbush'" name="" />
            <CastItem
              image={tatts}
              nickname="'Tatts'"
              name="Johnny Sohn
              "
            />
            <CastItem
              image={profile}
              nickname="'Profile'"
              name="Aanya Singh
              "
            />
            <CastItem image={mello} nickname="'Mello'" name="Asen Petrov" />
            <CastItem image={engage} nickname="'Engage'" name="Blake Marco" />
            <CastItem image={edge} nickname="'Edge'" name="Benny Francisco" />
            <CastItem
              image={divine}
              nickname="'Divine'"
              name="Leotie Mellody GoForth"
            />
            <CastItem image={rex} nickname="'Rex'" name="Jethro Loben" />
            <CastItem image={calamity} nickname="'Calamity'" name="Jane Liu" />
            <CastItem
              image={sleet}
              nickname="'Sleet'"
              name="Maxime Dubois
              "
            />
            <CastItem
              image={blackout}
              nickname="'Blackout'"
              name="Mara Collins
              "
            />
            <CastItem
              image={cinder}
              nickname="'Cinder'"
              name="Roscoe Powers
              "
            />

            <CastItem
              image={nix}
              nickname="'NIX'"
              name="Nicole Isabelle Xander
              "
            />

            <CastItem image={simon} nickname="'Simon'" name="Simon Johnson" />

            <CastItem image={sage} nickname="'Sage'" name="Zurie Collins" />

            <CastItem
              image={will}
              nickname="'Big Boy'"
              name="Will B. Myne
              "
            />
          </Flex>
        </ContentContainer>
      </PageContainer>
    );
  }
}

const PageContainer = styled.div<{ backgroundColor: string }>`
  font-family: "Montserrat", sans-serif;
  background-color: white;
  min-height: 100vh;

  padding: 0 90px;
  overflow-x: hidden;
  min-height: 100vh;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    padding: 0 100px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    padding: 0 70px;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    padding: 0 26px;
  }
`;

const Header = styled.h1`
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 900;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    font-size: 67px;
  }

  @media screen and (max-width: 470px) {
    text-align: center;
  }

  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    font-size: 50px;
  }

  @media screen and (max-width: 365px) {
    font-size: 45px;
  }
`;

const ContentContainer = styled.div`
  max-width: 90vw;
`;

const IntroContainer = styled.div`
  font-size: 20px;
  margin-top: 20px;
`;
const Intro = styled.p`
  line-height: 1.2;
  font-weight: 300;
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;

  }
`;
