import styled from "@emotion/styled";
import { Breakpoints } from "shared/constants";

export const PageContainer = styled.div<{ backgroundColor: string }>`
  font-family: "Montserrat", sans-serif;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 90px;
  overflow-x: hidden;
  min-height: 100vh;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    padding: 0 100px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    padding: 0 70px;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    padding: 0 26px;
  }
`;

export const PageContainerFlex = styled.div<{ backgroundColor: string }>`
  font-family: "Montserrat", sans-serif;
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 0 90px;
  // overflow-x: hidden;
  // min-height: 100vh;
  flex-direction: column;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    padding: 0 100px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    padding: 0 70px;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;
    padding: 0 26px;
  }
`;
