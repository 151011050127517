import { MerkleTree } from "merkletreejs";
import Web3 from "web3";
import keccak256 from "keccak256";

export default class MintAPI {
  merkle: MerkleTree;
  normalized: { [key: string]: boolean };

  constructor() {
    this.merkle = new MerkleTree([], keccak256);
    this.normalized = {};
  }

  formatAccessList(account: string) {
    const value = this.normalized[account];
    if (value) {
      return `${account}`;
    } else {
      return "";
    }
  }

  getProof(account: string, proof: string[]) {
    account = this.normalize(account);
    if (this.normalized[account]) {
      const hashed = keccak256(account);
      proof.push(...this.merkle.getHexProof(hashed));
      return true;
    } else {
      return false;
    }
  }

  loadAccessList(accesslist: { [key: string]: any }) {
    this.normalized = {};
    const accounts = Object.entries(accesslist).map(([account, value]) => {
      account = this.normalize(account);
      this.normalized[account] = value;
      return account;
    });
    accounts.sort();

    const hashes = accounts.map((account) => keccak256(account));
    this.merkle = new MerkleTree(hashes, keccak256, { sortPairs: true });
    const merkleRoot = this.merkle.getRoot().toString("hex");
    console.info({ merkleRoot });
  }

  normalize(account: string) {
    try {
      return Web3.utils.toChecksumAddress(account);
    } catch (err) {
      console.warn(err);
      return account;
    }
  }
}
