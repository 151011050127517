import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Navbar } from "components/Navbar";
import { Home } from "pages/Home";
import { HomeBase } from "pages/HomeBase";
// import { Solution } from "pages/Solution";
// import { Lineup } from "pages/Lineup";
import { Cast } from "pages/Cast";
import {
  HOME_BACKGROUND_COLOR,
  HOME_BASE_BACKGROUND_COLOR,
  Pages,
  THE_CAST_BACKGROUND_COLOR,
  // THE_LINEUP_BACKGROUND_COLOR,
  // THE_SOLUTION_BACKGROUND_COLOR,
} from "shared/constants";
import "./reset.css";
import styled from "@emotion/styled";

export function App() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [pathname]);

  function backgroundColor(pathname: string) {
    switch (pathname) {
      case Pages.HOME: {
        return HOME_BACKGROUND_COLOR;
      }
      case Pages.HOME_BASE: {
        return HOME_BASE_BACKGROUND_COLOR;
      }
      // case Pages.SOLUTION: {
      //   return THE_SOLUTION_BACKGROUND_COLOR;
      // }
      // case Pages.LINEUP: {
      //   return THE_LINEUP_BACKGROUND_COLOR;
      // }
      case Pages.CAST: {
        return THE_CAST_BACKGROUND_COLOR;
      }
      default:
        return "white";
    }
  }

  return (
    <Container
      backgroundColor={backgroundColor(pathname)}
      isMobileMenuOpen={isMobileMenuOpen}
    >
      <Navbar
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        backgroundColor={backgroundColor(pathname)}
      />
      <Routes>
        <Route
          path={Pages.HOME}
          element={<Home isMobileMenuOpen={isMobileMenuOpen} />}
        />
        <Route
          path={Pages.HOME_BASE}
          element={<HomeBase isMobileMenuOpen={isMobileMenuOpen} />}
        />
        {/* <Route
          path={Pages.SOLUTION}
          element={<Solution isMobileMenuOpen={isMobileMenuOpen} />}
        />
        <Route
          path={Pages.LINEUP}
          element={<Lineup isMobileMenuOpen={isMobileMenuOpen} />}
        /> */}
        <Route
          path={Pages.CAST}
          element={<Cast isMobileMenuOpen={isMobileMenuOpen} />}
        />
      </Routes>
    </Container>
  );
}

const Container = styled.main<{
  backgroundColor: string;
  isMobileMenuOpen: boolean;
}>`
  position: relative;
  overflow-x: hidden;
  min-height: 100vh;
  height: 100%;
  // width: 300px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ backgroundColor, isMobileMenuOpen }) =>
    isMobileMenuOpen ? "white" : "transparent"};
`;
