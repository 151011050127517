import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { Contract } from "web3-eth-contract";
import { IChain, Wallet } from "./wallet";

declare let window: any;

interface IEthereumSessionArgs {
  chain: IChain;
  contractAddress: string;
  contractABI: AbiItem[];
}

export default class EthereumSession {
  chain: IChain;
  contract?: Contract;
  contractAddress: string;
  contractABI: AbiItem[];
  provider: any;
  wallet: Wallet;

  //  ethersProvider: Web3Provider;
  web3client: Web3;

  static COMMON_CHAINS: { [key: number | string]: IChain };

  constructor(args: IEthereumSessionArgs) {
    this.chain = args.chain;
    this.contractAddress = args.contractAddress;
    this.contractABI = args.contractABI;
    this.wallet = new Wallet();

    this.provider = window.ethereum;
    //this.ethersProvider = new Web3Provider(this.provider, "any");
    this.web3client = new Web3();
  }

  async addChain(chain: IChain) {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [{ chainId: chain.hex, rpcUrls: chain.rpcURL }],
        });
        return true;
      }
    } catch (err) {}

    return false;
  }

  async connectWeb3(deep: boolean, provider: any) {
    //let subscribe = false;
    if (!this.web3client.currentProvider || provider !== this.provider) {
      if (provider === window.ethereum) this.debug("using browser");
      else this.debug("using NETWORK override");

      //subscribe = true;
      this.contract = undefined;
      this.provider = provider;
      this.web3client = new Web3(provider);
    }

    if (!this.web3client) {
      this.warn("No web3 provider");
      return false;
    }

    if (!this.contract) {
      this.contract = new this.web3client.eth.Contract(
        this.contractABI,
        this.contractAddress
      );
      (this.contract as any).setProvider(this.provider);
    }

    try {
      if (!(window.ethereum && window.ethereum.isConnected())) return false;
    } catch (err) {
      this.debug(err);
    }

    //if( subscribe )
    //  this.subscribe();

    if (!(await this.connectChain(deep))) return false;

    if (!(await this.connectAccounts(deep))) return false;

    return true;
  }

  async connectAccounts(deep: boolean) {
    if (this.hasAccounts()) return true;

    this.wallet.accounts = await this.getWalletAccounts();
    if (this.hasAccounts()) return true;

    if (deep) {
      this.wallet.accounts = await this.requestWalletAccounts();
      return this.hasAccounts();
    }

    return false;
  }

  async connectChain(deep: boolean) {
    if (this.isChainConnected()) return true;

    let chainID: number | string;
    if (deep) {
      chainID = await this.getWalletChainID();
      this.wallet.chain = EthereumSession.getChain(chainID);
      if (this.isChainConnected()) return true;
    }

    chainID = await this.getWalletChainID();
    this.wallet.chain = EthereumSession.getChain(chainID);
    if (this.isChainConnected()) return true;

    if (deep) {
      if (await this.setChainID(this.chain.hex)) {
        chainID = await this.getWalletChainID();
        this.wallet.chain = EthereumSession.getChain(chainID);
        return this.isChainConnected();
      }

      if (await this.addChain(this.chain)) {
        chainID = await this.getWalletChainID();
        this.wallet.chain = EthereumSession.getChain(chainID);
        if (this.isChainConnected()) return true;

        if (await this.setChainID(this.chain.hex)) {
          chainID = await this.getWalletChainID();
          this.wallet.chain = EthereumSession.getChain(chainID);
          return this.isChainConnected();
        }
      }
    }

    return false;
  }

  static getChain(chainID: number | string): IChain | undefined {
    if (chainID in EthereumSession.COMMON_CHAINS)
      return EthereumSession.COMMON_CHAINS[chainID];

    if (typeof chainID === "string") {
      chainID = parseInt(chainID);
      if (chainID in EthereumSession.COMMON_CHAINS)
        return EthereumSession.COMMON_CHAINS[chainID];
    }

    return undefined;
  }

  static getError(err: any) {
    if (err && err.code === 4001) {
      err.stack = null;
      return err;
    }

    let text = JSON.stringify(err);
    if (text && text !== "{}") return err;

    let newError: any = null;
    const message = err.message ? err.message : String(err);
    const start = message.indexOf("{");
    if (start > -1) {
      newError = new Error(message.substring(0, start).replace(/\s+$/, ""));
      if (err.stack) {
        newError.stack = err.stack;
      }

      const end = message.lastIndexOf("}");
      if (end > -1) {
        const json = message.substr(start, end - start + 1);

        try {
          const unwrapped = JSON.parse(json);
          if (unwrapped.originalError) {
            if (unwrapped.originalError.message === newError.message) {
              newError = unwrapped.originalError;
            } else {
              newError.originalError = unwrapped.originalError;
            }
          } else {
            if (unwrapped.message === newError.message) {
              newError = unwrapped;
            } else {
              newError.originalError = unwrapped;
            }
          }

          return newError;
        } catch (innerError) {
          console.warn(innerError);
        }
      }
    }

    return err;
  }

  async getWalletAccounts() {
    const isAllowed = await this.isWalletAllowed();
    if (isAllowed !== false) {
      try {
        //const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        //const accounts = await this.ethersProvider.listAccounts();
        const accounts = await this.web3client.eth.getAccounts();
        //console.info({ getWalletAccounts: accounts })
        return accounts;
      } catch (err) {
        this.warn("getWalletAccounts", err);
        return [];
      }
    } else {
      return [];
    }
  }

  async getWalletChainID(): Promise<string | number> {
    try {
      //window.ethereum.request({ method: 'eth_chainId' });
      const chainID = await this.web3client.eth.getChainId();
      //console.info({ getWalletChainID: chainID })
      return chainID;
      //const network: Network = await this.ethersProvider.getNetwork();
      //if (network?.chainId)
      //  return network.chainId;
    } catch (err) {
      this.warn("getWalletChainID", err);
    }

    return -1;
  }

  isChainConnected() {
    if (this.wallet.chain)
      return this.wallet.chain.decimal === this.chain.decimal;
    else return false;
  }

  isConnected() {
    try {
      if (!(window.ethereum && window.ethereum.isConnected())) return false;
    } catch (err) {
      this.debug(err);
    }

    if (!this.isChainConnected()) return false;

    if (!this.hasAccounts()) return false;

    return true;
  }

  async isWalletAllowed() {
    try {
      if (window.ethereum) {
        const permissions: any = await window.ethereum.request({
          method: "wallet_getPermissions",
        });
        //console.info({ isWalletAllowed: permissions })
        return permissions.some(
          (p: any) => p.parentCapability === "eth_accounts"
        );
      }
    } catch (err) {
      this.warn("isWalletAllowed", err);
    }
    return null;
  }

  hasAccounts() {
    return !!(this.wallet.accounts && this.wallet.accounts.length);
  }

  //unlock
  async requestWalletAccounts(): Promise<string[]> {
    try {
      if (window.ethereum) {
        const accounts = await this.web3client.eth.getAccounts();
        /*
        const accounts: string[] = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        */
        //console.info({ 'requestWalletAccounts': accounts });
        return accounts;
      }
    } catch (err: any) {
      if (err.code === -32002) {
        alert(`Help!  Unlock your wallet and try again.`);
      } else if (err.code === 4001) {
        alert(`Oops!  No account(s) selected, try again.`);
      } else {
        this.warn("requestWalletAccounts", err);
        alert(`Oops!  Unknown wallet error, check your wallet and try again.`);
      }
    }

    return [];
  }

  async setChainID(hexChainID: string) {
    try {
      if (window.ethereum) {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: hexChainID }],
        });
        return true;
      }
    } catch (err: any) {
      if (err.code === 4001) {
        //user rejected selection
      } else if (err.code === 4902) {
        //add failed
      }
    }

    return false;
  }

  /**
   * logging
   **/
  debug(...arg1: any) {
    const args = Array.prototype.slice.call(arguments);
    console.debug(...args);
    this.log("DEBUG", ...args);
  }

  error(...arg1: any) {
    const args: any[] = Array.prototype.slice.call(arguments);
    console.error(...args);
    this.log("ERROR", ...args);
  }

  info(...arg1: any) {
    const args = Array.prototype.slice.call(arguments);
    console.info(...args);
    this.log("INFO", ...args);
  }

  log(severity: string, ...arg1: any) {
    try {
      const logs = document.getElementById("logs");
      if (logs) {
        const hr = document.createElement("hr");
        logs.appendChild(hr);

        for (let i = 0; i < arguments.length; ++i) {
          const json = document.createTextNode(JSON.stringify(arguments[i]));
          logs.appendChild(json);
        }
      }
    } catch (_) {}
  }

  warn(...arg1: any) {
    const args = Array.prototype.slice.call(arguments);
    console.warn(...args);
    this.log("WARN", ...args);
  }
}

EthereumSession.COMMON_CHAINS = {
  1: {
    name: "Ethereum Mainnet",
    decimal: 1,
    hex: "0x1",
    explorer: "https://etherscan.io",
  },
  "0x1": {
    name: "Ethereum Mainnet",
    decimal: 1,
    hex: "0x1",
    explorer: "https://etherscan.io",
  },
  3: {
    name: "Ropsten Testnet",
    decimal: 3,
    hex: "0x3",
    rpcURL: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  },
  "0x3": {
    name: "Ropsten Testnet",
    decimal: 3,
    hex: "0x3",
    rpcURL: "https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  },
  4: {
    name: "Rinkeby Testnet",
    decimal: 4,
    hex: "0x4",
    rpcURL: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    explorer: "https://rinkeby.etherscan.io",
  },
  "0x4": {
    name: "Rinkeby Testnet",
    decimal: 4,
    hex: "0x4",
    rpcURL: "https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    explorer: "https://rinkeby.etherscan.io",
  },
  5: {
    name: "Goerli Testnet",
    decimal: 5,
    hex: "0x5",
    rpcURL: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  },
  "0x5": {
    name: "Goerli Testnet",
    decimal: 5,
    hex: "0x5",
    rpcURL: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  },
  42: {
    name: "Kovan Testnet",
    decimal: 42,
    hex: "0x2a",
    rpcURL: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  },
  "0x2a": {
    name: "Kovan Testnet",
    decimal: 42,
    hex: "0x2a",
    rpcURL: "https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  },
  56: {
    name: "Binance Mainnet",
    decimal: 56,
    hex: "0x38",
    rpcURL: "https://bsc-dataseed.binance.org/",
  },
  "0x38": {
    name: "Binance Mainnet",
    decimal: 56,
    hex: "0x38",
    rpcURL: "https://bsc-dataseed.binance.org/",
  },
  97: {
    name: "Binance Testnet",
    decimal: 97,
    hex: "0x57",
    rpcURL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  "0x57": {
    name: "Binance Testnet",
    decimal: 97,
    hex: "0x57",
    rpcURL: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  137: {
    name: "Polygon (Matic)",
    decimal: 137,
    hex: "0x89",
    rpcURL: "https://polygonscan.com/",
  },
  "0x89": {
    name: "Polygon (Matic)",
    decimal: 137,
    hex: "0x89",
    rpcURL: "https://polygonscan.com/",
  },
  80001: {
    name: "Polygon Mumbai Testnet",
    decimal: 80001,
    hex: "0x13881",
    rpcURL: "https://matic-mumbai.chainstacklabs.com/",
  },
  "0x13881": {
    name: "Polygon Mumbai Testnet",
    decimal: 80001,
    hex: "0x13881",
    rpcURL: "https://matic-mumbai.chainstacklabs.com/",
  },
};
