import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import {
  DISCORD_URL,
  Pages,
  TWITTER_URL,
  OPEN_SEA_URL,
} from "shared/constants";
import openSeaIcon from "shared/images/open-sea.png";
import twitterIcon from "shared/images/twitter.png";
import discordIcon from "shared/images/discord.png";

export function MobileMenu() {
  let activeStyle = {
    fontWeight: 700,
  };

  let normalStyle = {
    fontWeight: 700,
  };

  return (
    <Container>
      <NavigationLink
        to={Pages.HOME_BASE}
        style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
      >
        About Us
      </NavigationLink>
      <NavigationLink
        to={Pages.SOLUTION}
        style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
      >
        The Journey
      </NavigationLink>
      {/* <NavigationLink
        to={Pages.LINEUP}
        style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
      >
        The Lineup
      </NavigationLink> */}
      {/* <NavigationLink
        to={Pages.CAST}
        style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
      >
        The Cast
      </NavigationLink> */}
      {/*
        <NavigationLink
          to={Pages.MINT}
          style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
        >
          Mint
        </NavigationLink>
      */}
      <SocialContainer>
        <a href={TWITTER_URL} target="_blank" rel="noreferrer">
          <Twitter src={twitterIcon} alt="twitter icon" />
        </a>
        <a href={DISCORD_URL} target="_blank" rel="noreferrer">
          <Discord src={discordIcon} alt="discord icon" />
        </a>

        <a href={OPEN_SEA_URL} target="_blank" rel="noreferrer">
          <OpenSea src={openSeaIcon} alt="open sea icon" />
        </a>
      </SocialContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const SocialContainer = styled.div`
  position: fixed;
  bottom: 0;
  display: flex;
  padding: 0 0 36px 34px;
  background-color: white;
`;

const NavigationLink = styled(NavLink)`
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  color: black;
  height: 80px;
  padding: 25px 25px;
  text-transform: uppercase;
  font-weight: 900;
  border-bottom: 1px solid #f8f4f4;

  &:first-of-type {
    margin-top: 70px;
  }
`;

const OpenSea = styled.img`
  width: 28px;
  height: 30px;
`;

const Discord = styled.img`
  transform: translateY(2px);
  width: 29px;
  height: 28px;
  margin-right: 24px;
`;

const Twitter = styled.img`
  transform: translateY(4px);
  width: 26px;
  height: 20px;
  margin-right: 24px;
`;
