import styled from "@emotion/styled";

function getHeight({
  size,
  axis,
}: {
  size: number;
  axis?: "horizontal" | "vertical";
}) {
  return axis === "horizontal" ? 1 : size;
}

function getWidth({
  size,
  axis,
}: {
  size: number;
  axis?: "horizontal" | "vertical";
}) {
  return axis === "vertical" ? 1 : size;
}

export const Spacer = styled.span`
  display: block;
  width: ${getWidth}px;
  min-width: ${getWidth}px;
  height: ${getHeight}px;
  min-height: ${getHeight}px;
`;
