export function CloseIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m12.796 10.94 8.91-8.91A1.222 1.222 0 0 0 19.983.305l-8.91 8.91L2.163.294A1.222 1.222 0 0 0 .439 2.017l8.91 8.922-8.922 8.91a1.223 1.223 0 1 0 1.724 1.724l8.922-8.91 8.91 8.91a1.223 1.223 0 0 0 1.723-1.724l-8.91-8.91z"
        fill="#000"
      />
    </svg>
  );
}
