import styled from "@emotion/styled";
import { NavLink, useLocation } from "react-router-dom";

import { useWindowSize } from "hooks/useWindowSize";
import {
  Breakpoints,
  DISCORD_URL,
  Pages,
  TWITTER_URL,
  OPEN_SEA_URL,
} from "shared/constants";
import navLogo from "shared/images/MefLogo.png";
// import navBanner from "shared/images/nav-banner.png";
import openSeaIcon from "shared/images/open-sea.png";
import twitterIcon from "shared/images/twitter.png";
import discordIcon from "shared/images/discord.png";
import { HamburgerIcon } from "components/HamburgerIcon";
import { CloseIcon } from "components/CloseIcon";
import { Dispatch, SetStateAction } from "react";

interface NavbarProps {
  isMobileMenuOpen: boolean;
  setIsMobileMenuOpen: Dispatch<SetStateAction<boolean>>;
  backgroundColor: string;
}

export function Navbar({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  backgroundColor,
}: NavbarProps) {
  const windowSize = useWindowSize();
  const { pathname } = useLocation();

  let activeStyle = {
    fontWeight: 600,
    color: "#BF0201",
  };

  let normalStyle = {
    fontWeight: 600,
  };

  if (windowSize && windowSize.width < 640) {
    return (
      <div style={{ display: "flex", padding: "0 25px" }}>
        <LogoLink pathname={pathname} to={Pages.HOME}>
          <Logo
            src={navLogo}
            alt="logo with the wu-tang symbol and the text 'no. 1 / 2022`"
          />
        </LogoLink>
        <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          {isMobileMenuOpen ? <CloseIcon /> : <HamburgerIcon />}
        </button>
      </div>
    );
  } else {
    return (
      <Container backgroundColor={backgroundColor}>
        <LogoLink pathname={pathname} to={Pages.HOME}>
          <Logo
            src={navLogo}
            alt="logo with the wu-tang symbol and the text 'no. 1 / 2022`"
          />
        </LogoLink>
        <Link
          to={Pages.HOME_BASE}
          style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
        >
          About Us
        </Link>
        {/* <Link
          to={Pages.SOLUTION}
          style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
        >
          The Journey
        </Link>
        <Link
          to={Pages.LINEUP}
          style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
        >
          The Lineup
        </Link> */}
        <Link
          to={Pages.CAST}
          style={({ isActive }) => (isActive ? activeStyle : normalStyle)}
        >
          The Cast
        </Link>
        {/* <Spacer axis="horizontal" size={22} /> */}
        {/* <Link to={ROUTES.HOME}>Mint</Link> */}
        <a href={TWITTER_URL} target="_blank" rel="noreferrer">
          <Twitter src={twitterIcon} alt="twitter icon" />
        </a>
        <a href={DISCORD_URL} target="_blank" rel="noreferrer">
          <Discord src={discordIcon} alt="discord icon" />
        </a>

        <a href={OPEN_SEA_URL} target="_blank" rel="noreferrer">
          <OpenSea src={openSeaIcon} alt="open sea icon" />
        </a>
      </Container>
    );
  }
}
const Container = styled.header<{ backgroundColor: string }>`
  display: flex;
  justify-content: end;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  z-index: 100;
  padding: 0 91px;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    padding: 0 70px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    padding: 0 70px;
  }
`;

const Link = styled(NavLink)`
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: black;
  margin-right: 36px;
  font-weight: 600;
  text-transform: uppercase;
  transition-timing-function: ease-out;
  transition: 0.25s;
  &:hover {
    color: #bf0201;
  }

  &:last-of-type {
    margin-right: 29px;
  }

  @media screen and (max-width: 1415px) {
    font-size: 18px;
    margin-right: 30px;
  }

  @media screen and (max-width: 1358px) {
    font-size: 14px;
    margin-right: 20px;

    &:last-of-type {
      margin-right: 18px;
    }
  }

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    &:last-of-type {
      margin-right: 17px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin-right: 16px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    font-size: 12px;
    margin-right: 14px;
  }
`;

const Logo = styled.img`
  width: 82px;
  height: 137px;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    width: 59px;
    height: 99px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    width: 50px;
    height: 84px;
  }

  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    width: 47px;
    height: 79px;
  }
`;

const LogoLink = styled(NavLink)<{ pathname: string }>`
  margin-right: auto;
`;

const OpenSea = styled.img`
  width: 28px;
  height: 29px;
  @media screen and (max-width: ${Breakpoints.TABLET}) {
    width: 18px;
    height: 19px;
  }
  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
  }
`;

const Discord = styled.img`
  width: 28px;
  height: 28px;
  margin-right: 14px;
  @media screen and (max-width: ${Breakpoints.TABLET}) {
    width: 18px;
    height: 18px;
  }
  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
  }
`;

const Twitter = styled.img`
  width: 27px;
  height: 23px;
  margin-right: 14px;
  @media screen and (max-width: ${Breakpoints.TABLET}) {
    width: 17px;
    height: 13px;
  }
  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
  }
`;
