import styled from "@emotion/styled";
import Vimeo from "@u-wave/react-vimeo";
import { Spacer } from "components/Spacer";
import { Breakpoints, HOME_BASE_BACKGROUND_COLOR } from "shared/constants";
import desktopHomeBaseImage from "shared/images/PFP-Ray.png";
import { PageContainer } from "shared/components/PageContainer";
import { Copy } from "shared/components/Copy";
import { MobileMenu } from "components/MobileMenu";

interface HomeBaseProps {
  isMobileMenuOpen: boolean;
}

export function HomeBase({ isMobileMenuOpen }: HomeBaseProps) {
  if (isMobileMenuOpen) {
    return <MobileMenu />;
  } else {
    return (
      <PageContainer backgroundColor={HOME_BASE_BACKGROUND_COLOR}>
        <DesktopImage
          src={desktopHomeBaseImage}
          alt="two mefaverse characters standing next to each other"
        />
        <CopyContainer>
          <Header>
            For the people
            <br /> by the people.
          </Header>
          <Spacer size={20} />
          <Copy>First project of its kind.</Copy>
          <Spacer size={24} />
          <Copy>
            The <strong>MEFaverse</strong> is the first metaversal world (Wu
            York City) where holders get to experience a graphic novel and not
            simply read it. The story revolves around Method Man, founding
            member of the greatest Hip Hop group of all time, Wu-Tang Clan, in a
            world where he became a literal superhero and not just a lyrical
            one.{" "}
          </Copy>
          <Spacer size={20} />
          <Copy>
            The project combines all things Hip Hop, skateboarding, and comic
            books in a format that speaks to all ages. This is where In Real
            Life (IRL) meets the metaverse.
          </Copy>
        </CopyContainer>
        <Video video="https://vimeo.com/689853001/1217fd98a4" responsive />
      </PageContainer>
    );
  }
}

const Header = styled.h1`
  font-size: 60px;
  line-height: 0.94;
  width: 670px;
  text-transform: uppercase;
  margin-top: 50px;
  font-weight: 900;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    font-size: 72px;
    width: 580px;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    font-size: 64px;
    width: 540px;
  }

  @media screen and (max-width: 600px) {
    width: 450px;
    font-size: 60px;
  }

  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    font-size: 50px;
    width: 100%;
    line-height: 1.17;
    letter-spacing: -3px;
    text-align: center;
    margin: 0 auto;
  }

  @media screen and (max-width: 366px) {
    font-size: 45px;
  }
`;

const CopyContainer = styled.div`
  max-width: 90vw;
  grid-row: 1;
  grid-column: 1;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    max-width: 60vw;
  }

  @media screen and (max-width: ${Breakpoints.TABLET_SMALL}) {
    max-width: 50vw;
  }

  @media screen and (max-width: 730px) {
    max-width: 55vw;
  }

  @media screen and (max-width: 640px) {
    margin-top: 20px;
    max-width: 100%;
  }

  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 40px;
  }
`;

const DesktopImage = styled.img`
  // max-width: 536px;
  //grid-column: 2;
  max-height: 800px;
  justify-self: center;
  margin-top: -40px;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    position: absolute;
    /* bottom: -100px; */
    right: -170px;
    z-index: 1;
  }

  @media screen and (max-width: 1024px) {
    right: -230px;
  }

  @media screen and (max-width: 810px) {
    right: -280px;
    bottom: -100px;
  }

  @media screen and (max-width: 768px) {
    right: -260px;
    bottom: -130px;
  }

  @media screen and (max-width: 640px) {
    position: initial;
    max-width: 311px;
    margin: 0 auto;
  }
`;

const Video = styled(Vimeo)`
  z-index: 10;
  grid-column: 1;
  margin-top: -215px;
  margin-bottom: 40px;

  @media screen and (max-width: ${Breakpoints.TABLET}) {
    margin-top: 30px;
    margin-bottom: 0;
    margin-bottom: 50px;
  }

  @media screen and (max-width: ${Breakpoints.MOBILE}) {
    margin-top: 0;
  }
`;
